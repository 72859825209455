import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["selectAllCheckbox", "recordCheckbox"];

connect() {
        console.log("Checkbox controller connected");
        console.log("Select All Target:", this.selectAllCheckboxTarget);
        console.log("Record Checkbox Targets:", this.recordCheckboxTargets);
        this.updateSelectAllState();
    }
    selectAll(event) {
        console.log("Checkbox: selectAll triggered");
        const isChecked = event.target.checked;
        this.recordCheckboxTargets.forEach(checkbox => {
            checkbox.checked = isChecked;
        });
    }

    updateSelectAllState() {
        console.log("Checkbox: updateSelectAllState");
        const allChecked = this.recordCheckboxTargets.every(checkbox => checkbox.checked);
        const someChecked = this.recordCheckboxTargets.some(checkbox => checkbox.checked);

        this.selectAllCheckboxTarget.indeterminate = !allChecked && someChecked;
        this.selectAllCheckboxTarget.checked = allChecked;
    }

    toggleRecordCheckbox() {
        console.log("Checkbox: toggleRecordCheckbox");
        this.updateSelectAllState();
    }
}